import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const ArtsWritingServicePage = () => (
  <Layout
    pageWrapperClassName="arts-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Art Essay Writing Service - Write My Arts Essay"
      meta={[
        {
          name: 'description',
          content: 'TakeAwayEssay.com 📝 is a custom art essay writing service with experienced writers, quick turnaround, and customer-friendly guarantees. It operates 24/7 and has thousands of satisfied customers who trust its quality arts essay writing service.',
        },

        {
          property: 'og:title',
          content: 'Custom Art Essay Writing Service for the Best Study Results | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'Custom art essay writing service at affordable prices with urgent deadlines. Order your custom paper now from a reliable provider. Compliance with all the requirements and standards is guaranteed. The service is available 24/7. 100% unique papers.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/arts-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        TakeAwayEssays.com:
        <br className="first-screen-section__title__br" />
        {' '}
        the art of essay writing
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Get your top-notch
        <br className="first-screen-section__heading__br" />
        {' '}
        plagiarism-free academic papers here
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Write my arts essay: art essay writing service
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Studying art is extremely interesting. However, it can be very demanding too. As a
            college student majoring in art, you have to write many assignments during a semester or
            quarter. At the end of the term, you must submit at least one research paper per course.
          </p>
          <p>
            It is difficult to keep the pace if you don’t possess good writing skills. What can you
            do in such a situation?{' '}
            <strong>
              Well, you can use our custom art essay writing service. This is the best way to get
              help in order to have top academic performance without stressing yourself too much.
            </strong>
          </p>
          <p>
            You may ask “what exactly can your service do for me?” Let us be straightforward: we can
            write any custom essay, paper, or dissertation you need. We don’t do it for free since
            we have to pay our writers’ salaries. However, we offer very affordable prices. Any
            student can pay for it!
          </p>
          <p>
            Our objective with our art essay writing service is to help as many students as
            possible. We want you to succeed and get the best grades. This is why we make all we can
            to keep our prices low. We know that most students don’t have much money at their
            disposal.
          </p>
          <p>
            If you want to learn more about our art essay writing service, we encourage you to read
            the entire article. We will explain in detail how it works and what advantages it has in
            comparison with other writing services.
          </p>
          <h2 className="h3">You can buy art essay directly on our website</h2>
          <p>You can buy art essay on our website. It is very easy. Follow these steps:</p>
          <ul className="dot-list">
            <li>First, you need to create an account. This will take just a few minutes.</li>
            <li>
              Then, you must fill out and submit an order form. You have to type in the required
              information about your assignment. Don’t worry about the security of the data you
              provide. All your information is private and confidential.
            </li>
            <li>
              After completing your order you will know how much your essay will cost. If you decide
              to buy it, submit the order and proceed to pay on our system. The payment system is
              secure.
            </li>
            <li>
              Upon receiving your payment, we will assign a suitable writer to do your assignment.
              He or she will ensure fast delivery, especially if your order is urgent.
            </li>
            <li>
              When the helper completes the task, you will get a draft. If everything is fine, you
              approve it and get an editable version. Otherwise, you can ask for a revision. This
              revision has no additional cost.
            </li>
          </ul>
          <p>As you can see, it will not take more than 10 minutes to buy art essay online.</p>
          <h2 className="h3">Who will help me write my art essay of high quality?</h2>
          <p>
            Many students ask this question. The answer is an art expert will do it. We have
            specialists in different areas of art. Hence, all our essays and papers are written by
            an expert in the corresponding subject matter. When you send us a “write my art essay”
            message, we look for the expert with the right background.
          </p>
          <p>
            Our team of professional writers comprises experts in art with high proficiency in
            academic writing. We hire the best rated writers to ensure the quality of the service we
            provide. You can confidently ask “do my art essay” as we have the right person to do it.
            Moreover, we have more than a decade of experience.
          </p>
          <p>
            We want to help you. This is why we offer you a great deal. You get a cheap paper that
            was written by an expert in the area. Who can beat this offer? Our custom art essay
            writing service is second to none. Now you know the answer to the question “who should
            help me do my assignment?”
          </p>
          <h2 className="h3">Pay for Art Essay and Get the Best Value for Your Money</h2>
          <p>
            Don’t hesitate to buy custom art essays online. We are the best option for art students.
            We offer a paid service, but the paper you get is the best value for your money. Owing
            to our non-plagiarism policy, you will get a 100% original essay.
          </p>
          <p>
            We offer some other guarantees like the possibility of a refund in case of order
            cancellation. What’s more, if you are not satisfied with our work, we can refund your
            payment. Hence, you shouldn’t have any concerns about our services. We are a legit
            writing company.
          </p>
          <p>
            We have the best art essays for sale. Buy one! It will be the best purchase for your
            college or university studies.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      Our essays sell like hot dogs.
      <br />
      {' '}
      Buy them now!
    </CTA>

  </Layout>
);

export default ArtsWritingServicePage;
